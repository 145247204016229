import React, { useCallback, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import TitlebarBelowImageList from "../components/ImageList/ImageList.tsx";
import BasicButtonGroup from "../components/Category/Category.tsx";
import SideAds from "../components/SideAds/SideAds.tsx";

const Filme = () => {
  const [preference, setPreference] = useState<number[]>([]);

  const [displayedItems, setDisplayedItems] = useState<any>([]);

  const getRandomItems = () => {
    const shufled = [...itemData].sort(() => 0.5 - Math.random());
    return shufled.slice(0, 1);
  };

  useEffect(() => {
    setDisplayedItems(getRandomItems());

    const interval = setInterval(() => {
      setDisplayedItems(getRandomItems());
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const addPreference = useCallback((newPreference: number) => {
    setPreference((prevPreferences) => {
      if (!prevPreferences.includes(newPreference)) {
        return [...prevPreferences, newPreference];
      }
      return prevPreferences;
    });
  }, []);

  const removePreference = useCallback((preferenceToRemove: number) => {
    setPreference((prevPreferences) =>
      prevPreferences.filter((pref) => pref !== preferenceToRemove)
    );
  }, []);

  return (
    <Grid container spacing={2} sx={{ flexDirection: "column" }}>
      <Grid
        item
        style={{
          borderRight: "1px solid black",
        }}
      >
        <BasicButtonGroup
          removePreference={removePreference}
          setPreference={addPreference}
        />
      </Grid>
      <Grid item>
        <TitlebarBelowImageList preference={preference} />
      </Grid>
      {displayedItems.map(
        (
          item: { link: string; img: string; title: string; author: string },
          index
        ) => (
          <SideAds link={item.link} img={item.img} title={item.title} />
        )
      )}
    </Grid>
  );
};

const itemData = [
  {
    link: `https://s.shopee.com.br/4L3RlDQn7m`,
    title: `E6s Fone De Ouvido Sem Fio Bluetooth 5.0 Tws 5`,
    img: `https://down-br.img.susercontent.com/file/br-11134201-23030-m2k0x7hkfoov90.webp`,
  },
  {
    link: `https://s.shopee.com.br/9A8hW8AkI4`,
    title: `Caixa De Som Extreme 3 Tamanho 23 CM ou 33 CM Bluetooth Rádio Pen-Drive 70W 120W`,
    img: `https://down-br.img.susercontent.com/file/br-11134207-7r98o-lyf99fxwub2t39@resize_w450_nl.webp`,
  },
  {
    link: `https://s.shopee.com.br/3fnkyB6soP`,
    title: `Canguru Moletom Casaco Blusão Frio Xxxtentacion Rip Rapper Bad`,
    img: `https://down-br.img.susercontent.com/file/br-11134201-23020-cxpeutof3lnvb8@resize_w450_nl.webp`,
  },
  {
    link: `https://s.shopee.com.br/8fCQvOUCiz`,
    title: `Kit 3 Sapatênis Masculino Casual Tênis Polo Fran + Relógio Carteira Cinto- London`,
    img: `https://down-br.img.susercontent.com/file/br-11134201-23010-wf6c1f08zqmv6a.webp`,
  },
  {
    link: `https://s.shopee.com.br/8AGAKUnIpF`,
    title: `Placa Pix Pagamento QrCode De Mesa Balcão c/ base - Acrílico`,
    img: `https://down-br.img.susercontent.com/file/br-11134207-7qukw-ljimjbss9v2i9f.webp`,
  },
  {
    link: `https://s.shopee.com.br/9f4y9fWdOf`,
    title: `Maquina de Barbear cortar cabelo Aparador Profissional barbeador acabamento Sem Fio Portátil`,
    img: `https://down-cvs-br.img.susercontent.com/7b95b6cd68b2c230f14881e33584057b.webp`,
  },
];

export default Filme;
