import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./Store.scss";
import { Box } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import { Link } from "react-router-dom";

const Store = () => {
  return (
    <div className="Container-shopping">
      <Box sx={{ margin: "1rem" }}>
        <Link to={"/"}>
          <Button variant="text" title="Voltar para tela inicial">
            <ArrowBackIosIcon sx={{ fontSize: "0.9rem", color: "white" }} />
            <HomeIcon sx={{ color: "white" }} />
          </Button>
        </Link>
      </Box>
      <Box>
        <p>
          Quer apoiar o nosso site? Faça suas compras pelos nossos links! Assim,
          você nos ajuda a continuar sem pagar nada a mais por isso. 🥰
        </p>
      </Box>
      <Box sx={{ margin: "2rem" }}>
        {renderItens.map((item) => (
          <Box key={item.key}>
            <h2>{item.title}</h2>
            <Box sx={{ overflowX: "auto" }}>
              <Box
                sx={{
                  display: "flex",
                  width: "max-content",
                  gap: "5px",
                  overflowX: "auto",
                }}
              >
                {item.itens.map((card) => (
                  <Card
                    sx={{
                      maxWidth: {
                        sm: 250,
                        xs: 200,
                      },
                      maxHeight: 250,
                      width: "100%",
                      position: "relative",
                      img: {
                        objectFit: "contain",
                      },
                    }}
                    key={card.title}
                  >
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        height="140"
                        image={card.img}
                        alt={card.title}
                      />
                      <CardContent
                        sx={{
                          padding: "0.2rem",
                          height: "58px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "0.8rem !important",
                          }}
                          className="titleProducts"
                        >
                          {card.title}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions sx={{ padding: "0.2rem" }}>
                      <Link to={card.link} target="_blank">
                        <Button
                          size="small"
                          color="primary"
                          variant="contained"
                          sx={{ width: "100%" }}
                        >
                          Ver mais
                        </Button>
                      </Link>
                    </CardActions>
                  </Card>
                ))}
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </div>
  );
};

const renderItens = [
  {
    key: 1,
    title: "Computadores e Informática",
    itens: [
      {
        link: `https://amzn.to/3DGai50`,
        title: `Havit HV-MP830 - Mouse Pad Professional Gaming, 30x90 cm`,
        img: `https://m.media-amazon.com/images/I/31VsRBgen0L._AC_SY450_.jpg`,
      },
      {
        link: `https://amzn.to/40kux0Q`,
        title: `Mouse sem fio Logitech M170 com Design Ambidestro Compacto`,
        img: `https://m.media-amazon.com/images/I/51+TpH1VS8L._AC_SY300_SX300_PQ10_.jpg`,
      },
      {
        link: `https://amzn.to/4gEDGHs`,
        title: `Combo de Teclado e Mouse Ergonômico Sem Fio com Conexão USB Wireless`,
        img: `https://m.media-amazon.com/images/I/71MW8ZR1vUL._AC_SY450_.jpg`,
      },
      {
        link: `https://amzn.to/4fEkati`,
        title: `Cabo Usb-C-Lightning + Fonte Carregador 25W Turbo Compatível iPhone X Xr SE 11 12 13 14 Premium LAGUS IMP.`,
        img: `https://m.media-amazon.com/images/I/5166ogGvNYL.__AC_SX300_SY300_QL70_ML2_.jpg`,
      },
      {
        link: `https://amzn.to/4gYd5Vx`,
        title: `ELG, F80N, Suporte Ergonômico Articulado de Mesa com Pistão a Gás, Design Compacto e Retrátil para Monitores de 17'' a 35'', Suporta de 2kg a 9kg,`,
        img: `https://m.media-amazon.com/images/I/51WEsVzT4SL.__AC_SX300_SY300_QL70_ML2_.jpg`,
      },
      {
        link: `https://amzn.to/40l5Tgs`,
        title: `Galaxy Tab A9+ 5G, Grafite, Tela 11", 64 GB, 4GB RAM, Câmera Frontal 5 MP Câmera Principal 8 MP`,
        img: `https://m.media-amazon.com/images/I/41zrg4ISTBL.__AC_SX300_SY300_QL70_ML2_.jpg`,
      },
    ],
  },
  {
    key: 2,
    title: "Games e Consoles",
    itens: [
      {
        link: `https://amzn.to/4gCGd4Y`,
        title: `Headphone Fone de Ouvido Havit HV-H2002d, Gamer, com Microfone, Falante 53mm, Plug 3.5mm`,
        img: `https://m.media-amazon.com/images/I/71i5jjOyOEL._AC_SX679_.jpg`,
      },
      {
        link: `https://amzn.to/40hTF8l`,
        title: `PlayStation®5 Slim Edição Digital com 2 Jogos`,
        img: `https://m.media-amazon.com/images/I/51SM5xU-M1L._AC_SX679_.jpg`,
      },
      {
        link: `https://amzn.to/400HdZq`,
        title: `Controle sem fio DualSense`,
        img: `https://m.media-amazon.com/images/I/51PmeLGEkML.__AC_SY300_SX300_QL70_ML2_.jpg`,
      },
      {
        link: `https://amzn.to/4iTM3Ai`,
        title: `Controle Dualshock 4 - PlayStation 4 - Preto`,
        img: `https://m.media-amazon.com/images/I/61kwdPH8+xL._AC_SX342_SY445_PQ10_.jpg`,
      },
      {
        link: `https://amzn.to/3BVHo02`,
        title: `Controle Usb Com Fio Para Xbox 360 Computador Notebook Preto`,
        img: `https://m.media-amazon.com/images/I/619noe3Hb4L._AC_SX679_.jpg`,
      },
      {
        link: `https://amzn.to/3W0Cea3`,
        title: `GAME STICK 4K ORIGINAL VERSÃO ATUALIZADA 11 MIL JOGOS com 2 controles sem fio`,
        img: `https://m.media-amazon.com/images/I/71NG+Y+dPOL._AC_SY300_SX300_PQ10_.jpg`,
      },
    ],
  },
  {
    key: 3,
    title: "Outros",
    itens: [
      {
        link: `https://s.shopee.com.br/4L3RlDQn7m`,
        title: `E6s Fone De Ouvido Sem Fio Bluetooth 5.0 Tws 5`,
        img: `https://down-br.img.susercontent.com/file/br-11134201-23030-m2k0x7hkfoov90.webp`,
      },
      {
        link: `https://s.shopee.com.br/9A8hW8AkI4`,
        title: `Caixa De Som Extreme 3 Tamanho 23 CM ou 33 CM Bluetooth Rádio Pen-Drive 70W 120W`,
        img: `https://down-br.img.susercontent.com/file/br-11134207-7r98o-lyf99fxwub2t39@resize_w450_nl.webp`,
      },
      {
        link: `https://s.shopee.com.br/3fnkyB6soP`,
        title: `Canguru Moletom Casaco Blusão Frio Xxxtentacion Rip Rapper Bad`,
        img: `https://down-br.img.susercontent.com/file/br-11134201-23020-cxpeutof3lnvb8@resize_w450_nl.webp`,
      },
      {
        link: `https://s.shopee.com.br/8fCQvOUCiz`,
        title: `Kit 3 Sapatênis Masculino Casual Tênis Polo Fran + Relógio Carteira Cinto- London`,
        img: `https://down-br.img.susercontent.com/file/br-11134201-23010-wf6c1f08zqmv6a.webp`,
      },
      {
        link: `https://s.shopee.com.br/8AGAKUnIpF`,
        title: `Placa Pix Pagamento QrCode De Mesa Balcão c/ base - Acrílico`,
        img: `https://down-br.img.susercontent.com/file/br-11134207-7qukw-ljimjbss9v2i9f.webp`,
      },
      {
        link: `https://s.shopee.com.br/9f4y9fWdOf`,
        title: `Maquina de Barbear cortar cabelo Aparador Profissional barbeador acabamento Sem Fio Portátil`,
        img: `https://down-cvs-br.img.susercontent.com/7b95b6cd68b2c230f14881e33584057b.webp`,
      },
    ],
  },
];
export default Store;
