import { Box } from "@mui/material";
import React from "react";

export const ContainerImage = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: {
          xl: "540px",
          md: "300px",
          xs: "162px",
        },
        background: `no-repeat url(${process.env.REACT_APP_URL_IMAGE}56u4HEtE27uBicriDXuVkUknXQ0.jpg)`,
        backgroundAttachment: "fixed",
        backgroundPosition: {
          lg: "top",
        },
        backgroundSize: {
          xs: "contain",
        },
      }}
    >
      <div
        style={{ width: "100%", height: "100%", backgroundColor: "#00000075" }}
      ></div>
    </Box>
  );
};
