import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "./SectionAds.scss";
import { Box } from "@mui/material";

const SectionAds = () => {
  const [displayedItems, setDisplayedItems] = useState<any>([]);

  const getRandomItems = () => {
    const shufled = [...itemData].sort(() => 0.5 - Math.random());
    return shufled.slice(0, 8);
  };

  useEffect(() => {
    setDisplayedItems(getRandomItems());

    // const interval = setInterval(() => {
    //   setDisplayedItems(getRandomItems());
    // }, 10000);

    // return () => clearInterval(interval);
  }, []);

  return (
    <div className={`container-section`}>
      <Box
        sx={{
          maxWidth: 1100,
          paddingInline: "1rem",
          gap: "10px !important",
          backgroundColor: "black",

          display: "grid",
          gridTemplateColumns: {
            md: "repeat(4, 1fr) !important",
            sm: "repeat(2, 1fr)",
          },
        }}
      >
        {displayedItems.map((item, index) => (
          <Link to={item.link} target="_blank">
            <img
              srcSet={`${item.img}`}
              src={`${item.img}`}
              alt={item.title}
              loading="lazy"
            />
            <div className="description">
              <h4>{item.title} </h4>
            </div>
          </Link>
        ))}
      </Box>
    </div>
  );
};

const itemData = [
  {
    link: `https://s.shopee.com.br/4L3RlDQn7m`,
    title: `E6s Fone De Ouvido Sem Fio Bluetooth 5.0 Tws 5`,
    img: `https://down-br.img.susercontent.com/file/br-11134201-23030-m2k0x7hkfoov90.webp`,
  },
  {
    link: `https://s.shopee.com.br/9A8hW8AkI4`,
    title: `Caixa De Som Extreme 3 Tamanho 23 CM ou 33 CM Bluetooth Rádio Pen-Drive 70W 120W`,
    img: `https://down-br.img.susercontent.com/file/br-11134207-7r98o-lyf99fxwub2t39@resize_w450_nl.webp`,
  },
  {
    link: `https://s.shopee.com.br/3fnkyB6soP`,
    title: `Canguru Moletom Casaco Blusão Frio Xxxtentacion Rip Rapper Bad`,
    img: `https://down-br.img.susercontent.com/file/br-11134201-23020-cxpeutof3lnvb8@resize_w450_nl.webp`,
  },
  {
    link: `https://s.shopee.com.br/8fCQvOUCiz`,
    title: `Kit 3 Sapatênis Masculino Casual Tênis Polo Fran + Relógio Carteira Cinto- London`,
    img: `https://down-br.img.susercontent.com/file/br-11134201-23010-wf6c1f08zqmv6a.webp`,
  },
  {
    link: `https://s.shopee.com.br/8AGAKUnIpF`,
    title: `Placa Pix Pagamento QrCode De Mesa Balcão c/ base - Acrílico`,
    img: `https://down-br.img.susercontent.com/file/br-11134207-7qukw-ljimjbss9v2i9f.webp`,
  },
  {
    link: `https://s.shopee.com.br/9f4y9fWdOf`,
    title: `Maquina de Barbear cortar cabelo Aparador Profissional barbeador acabamento Sem Fio Portátil`,
    img: `https://down-cvs-br.img.susercontent.com/7b95b6cd68b2c230f14881e33584057b.webp`,
  },
  {
    link: `https://s.shopee.com.br/LXZ4ZHHUP`,
    title: `Mochila Escolar Resistente Bem Espaçosa Faculdade Trabalho Viagem`,
    img: `https://down-tx-br.img.susercontent.com/br-11134207-7r98o-m0tcm02knee86b.webp`,
  },
  {
    link: `https://s.shopee.com.br/6fRccFv7Z7`,
    title: `Cortador Multifuncional Fatiador De Verduras/frutas/legumes
`,
    img: `https://down-tx-br.img.susercontent.com/sg-11134201-7rd5o-lv64ckrcz05t40.webp`,
  },
];
export default SectionAds;
