import React, { useState } from "react";

import "./Navbar.scss";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";

const style = {
  color: "#09a3ad",
  fontWeight: 700,
  fontSize: "large",
};

export const Navbar = () => {
  const [selected, setSelected] = useState("");
  const [search, setSearch] = useState("");

  const handleClick = (label: string) => {
    setSelected(label);
  };

  const handleSearch = () => {
    window.location.href = `/search/${search}`;
  };

  window.addEventListener("keydown", (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  });

  return (
    <nav className="container-navbar">
      <div onClick={() => handleClick("")}>
        <Link to="/">
          Cine<span style={{ color: "rgb(177 213 255)" }}>Tok</span>
        </Link>
      </div>
      <div className="navbar-list">
        <ul>
          <li onClick={() => handleClick("Filmes")}>
            <Link style={selected === "Filmes" ? style : {}} to="/filmes">
              Filmes
            </Link>
          </li>
          <li onClick={() => handleClick("Series")}>
            <Link style={selected === "Series" ? style : {}} to="/series">
              Séries
            </Link>
          </li>
          <li onClick={() => handleClick("Store")}>
            <Link to="/help-us">Loja</Link>
          </li>
        </ul>
      </div>
      <div className="navbar-search">
        <input
          type="text"
          onChange={(e) => setSearch(e.target.value)}
          aria-label="Search"
          placeholder="Search"
        />
        <SearchIcon
          sx={{
            position: "absolute",
            right: "10px",
            width: "25px",
            cursor: "pointer",
          }}
          onClick={handleSearch}
        />
      </div>
    </nav>
  );
};
